export default {
  getPath(image, width, height) {
    let path = 'https://res.cloudinary.com/sopamo/image/upload/c_fill,f_auto,q_auto:good'
    if (height) {
      path += `,h_${height}`
    }
    if (width) {
      path += `,w_${width}`
    }
    path += '/v1547226193/elektromobil-aktiv'
    path += image
    return path
  },
}
