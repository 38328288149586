<template>
  <v-container fluid>
    <v-col
      cols="12"
      v-if="loading">
      <div class="text-center" >
        <v-progress-circular
          indeterminate
          color="primary" />
      </div>
    </v-col>
    <v-stepper
      v-model="stepper"
      v-else>
      <v-stepper-header>
        <v-stepper-step
          :complete="stepper > 1"
          @click="stepper = 1"
          step="1">
          Basisdaten
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="stepper > 2"
          @click="stepper = 2"
          step="2">
          Attribute
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="stepper > 3"
          @click="stepper = 3"
          step="3">
          Bilder
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          @click="stepper = 4"
          step="4">
          Absenden
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-input>
            <v-text-field
              label="Name"
              outlined
              v-model="product.name"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-select
              :items="manufacturers"
              item-text="name"
              item-value="id"
              label="Hersteller"
              outlined
              v-model="product.manufacturer_id"
            ></v-select>
          </v-input>
          <v-input>
            <v-text-field
              hint="Ein Satz der die Vorzüge dieses Modells beschreibt"
              label="Intro"
              outlined
              persistent-hint
              v-model="product.intro"
            ></v-text-field>
          </v-input>
          <v-input>
            <v-textarea
              height="500"
              hint="Ausführliche Beschreibung dieses Modells"
              label="Beschreibung"
              outlined
              persistent-hint
              v-model="product.description"
            ></v-textarea>
          </v-input>
          <v-input>
            <v-switch
              v-model="product.visible_filter"
              :label="product.visible_filter ? 'Bei Onlineberatungen anzeigen' : 'Bei Onlineberatungen ausblenden'"
            />
          </v-input>

          <v-btn
            @click="stepper = 2"
            color="primary"
          >
            Weiter
          </v-btn>
        </v-stepper-content>
        <v-stepper-content step="2">
          <template
            v-if="attributes"
          >
            <v-row
              :key="attributeGroupKey"

              v-for="(attributeGroup, attributeGroupKey) in attributeGroups"
            >
              <v-col cols="12">
                <h3>{{ attributeGroup.title }}</h3>
              </v-col>
              <v-col
                v-for="attribute in attributeGroup.attributes"
                cols="12"
                :key="attribute.id"
              >
                <div style="max-width: 500px">
                  <v-text-field
                    :label="attribute.name"
                    :required="attribute.required"
                    type="number"
                    v-if="attribute.dataType === 'number'"
                    v-model="product.attributes[attribute.id]"
                  >
                    <template
                      slot="append"
                      v-if="attribute.unit"
                    >
                      {{ attribute.unit }}
                    </template>
                  </v-text-field>
                  <v-checkbox
                    :label="attribute.name"
                    v-if="attribute.dataType === 'boolean'"
                    v-model="product.attributes[attribute.id]"
                  />
                  <v-radio-group
                    :label="attribute.name"
                    v-if="attribute.dataType === 'select'"
                    v-model="product.attributes[attribute.id]"
                  >
                    <v-radio
                      :key="attribute.id + '-' + option"
                      :label="title"
                      :value="option"
                      v-for="(title, option) in attribute.options"
                    />
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </template>
          <v-btn
            @click="stepper = 3"
            color="primary"
          >
            Weiter
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-container>
            <v-row  >
              <v-col
                cols="4"
                :key="asset.path"
                v-for="asset in images"
                class="img-wrapper-container">
                <div class="img-wrapper">
                  <img :src="cloudinaryImages.getPath(asset.path)">
                </div>
                <v-btn
                  color="error"
                  @click.prevent="removeAsset(asset)">
                  Entfernen
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <FilePond
            accepted-file-types="image/jpeg, image/png"
            allow-multiple="true"
            label-idle="Dateien zum Hochladen hier ablegen..."
            name="file"
            ref="pond"
            :server="imageUploadURL"
          />

          <v-btn
            @click="stepper = 4"
            color="primary"
          >
            Weiter
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <p>
            TODO: Validierung der Eingabefelder
          </p>
          <v-btn
            @click="store"
            :disabled="isSaving"
            color="success">
            Produkt speichern
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import images from '@/plugins/images'
import ProductsAPI from '@/api/products'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)
export default {
  data() {
    return {
      stepper: 1,
      product: {
        name: '',
        intro: '',
        description: '',
        manufacturer_id: null,
        attributes: {},
        assets: [],
        visible_filter: true,
      },
      attributes: null,
      manufacturers: null,
      cloudinaryImages: images,
      loading: false,
      isSaving: false,
    }
  },
  created() {
    this.loading = true
    Promise.all([ProductsAPI.getProductModels(), ProductsAPI.getManufacturers()]).then((response) => {
      if (response[1]) {
        this.manufacturers = response[1]
      }

      if (response[0]) {
        const data = response[0]
        this.attributes = data.attributes

        // Make sure all checkboxes are set to false initially
        Object.keys(this.attributes).forEach((attributeKey) => {
          const attribute = this.attributes[attributeKey]
          if (attribute.dataType === 'boolean') {
            this.$set(this.product.attributes, attribute.id, false)
          }
        })
        if (this.$route.params.id && this.$route.params.id !== 'neu') {
          this.product = data.product_models[this.$route.params.id]
          if (this.product) {
            const temporaryAttributes = {}
            Object.values(this.product.attributes).forEach((attribute) => {
              temporaryAttributes[attribute.id] = attribute.value
            })
            this.product.attributes = temporaryAttributes
          }
        }
      }

      this.loading = false
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e)
    })
  },
  computed: {
    images() {
      return this.product.assets.filter((item) => item.type === 0)
    },
    attributeGroups() {
      if (!this.attributes) {
        return {}
      }
      const groups = {
        technical_data: {
          title: 'Technische Daten',
          attributes: [],
        },
        accessories: {
          title: 'Ausstattung',
          attributes: [],
        },
        optional_accessories: {
          title: 'Optionales Zubehör',
          attributes: [],
        },
        colors: {
          title: 'Farben',
          attributes: [],
        },
      }

      Object.keys(this.attributes).forEach((attributeKey) => {
        const attribute = this.attributes[attributeKey]
        if (typeof groups[attribute.category] === 'undefined') {
          // eslint-disable-next-line no-console
          console.log(attribute)
        } else {
          groups[attribute.category].attributes.push(attribute)
        }
      })
      Object.keys(groups).forEach((groupKey) => {
        groups[groupKey].attributes.sort((a, b) => a.position - b.position)
      })

      return groups
    },
    imageUploadURL() {
      return process.env.VUE_APP_API_BASE.replace(/api$/, 'filepond/api/process')
    },
  },
  methods: {
    store() {
      if (this.isSaving) {
        return
      }
      this.isSaving = true
      this.product.assets = this.$refs.pond.getFiles().map((file) => file.serverId)
      const apiCall = this.$route.params.id === 'neu'
        ? ProductsAPI.storeProduct(this.product)
        : ProductsAPI.updateProduct(this.product)
      apiCall.then(() => {
        this.$toasted.success('Das Produkt wurde erfolgreich gespeichert.')
        this.$router.push('/product-management')
      }).finally(() => {
        this.isSaving = false
      })
    },
    removeAsset(asset) {
      if (this.product && !this.product.removedAssets) {
        this.product.removedAssets = []
      }

      if (asset.id) {
        this.product.removedAssets.push(asset.id)
        this.product.assets = this.product.assets.filter((item) => item.id !== asset.id)
      }
    },
  },
  components: {
    FilePond,
  },
}
</script>

<style lang="scss">
  $max-grid-cell-height: 250px;
  $max-grid-cell-image-height: 250px;

  .img-wrapper-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .img-wrapper {
      margin: 0 1px;
      padding: 2px;
      border: 2px solid #0d47a1;
      border-radius: 3px;
      min-height: $max-grid-cell-height;
      text-align: center;

      img {
        max-width: 100%;
        max-height: $max-grid-cell-image-height;
      }
    }
  }
</style>
